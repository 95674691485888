// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ages-js": () => import("./../../../src/pages/ages.js" /* webpackChunkName: "component---src-pages-ages-js" */),
  "component---src-pages-audition-results-js": () => import("./../../../src/pages/audition-results.js" /* webpackChunkName: "component---src-pages-audition-results-js" */),
  "component---src-pages-auditionresults-js": () => import("./../../../src/pages/auditionresults.js" /* webpackChunkName: "component---src-pages-auditionresults-js" */),
  "component---src-pages-auditions-js": () => import("./../../../src/pages/auditions.js" /* webpackChunkName: "component---src-pages-auditions-js" */),
  "component---src-pages-authorization-js": () => import("./../../../src/pages/authorization.js" /* webpackChunkName: "component---src-pages-authorization-js" */),
  "component---src-pages-barcode-js": () => import("./../../../src/pages/barcode.js" /* webpackChunkName: "component---src-pages-barcode-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-classes-acrobatics-js": () => import("./../../../src/pages/classes/acrobatics.js" /* webpackChunkName: "component---src-pages-classes-acrobatics-js" */),
  "component---src-pages-classes-ballet-js": () => import("./../../../src/pages/classes/ballet.js" /* webpackChunkName: "component---src-pages-classes-ballet-js" */),
  "component---src-pages-classes-ballroom-js": () => import("./../../../src/pages/classes/ballroom.js" /* webpackChunkName: "component---src-pages-classes-ballroom-js" */),
  "component---src-pages-classes-contemporary-js": () => import("./../../../src/pages/classes/contemporary.js" /* webpackChunkName: "component---src-pages-classes-contemporary-js" */),
  "component---src-pages-classes-flamenco-js": () => import("./../../../src/pages/classes/flamenco.js" /* webpackChunkName: "component---src-pages-classes-flamenco-js" */),
  "component---src-pages-classes-hip-hop-js": () => import("./../../../src/pages/classes/hip-hop.js" /* webpackChunkName: "component---src-pages-classes-hip-hop-js" */),
  "component---src-pages-classes-jazz-js": () => import("./../../../src/pages/classes/jazz.js" /* webpackChunkName: "component---src-pages-classes-jazz-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-classes-lyrical-js": () => import("./../../../src/pages/classes/lyrical.js" /* webpackChunkName: "component---src-pages-classes-lyrical-js" */),
  "component---src-pages-classes-tap-js": () => import("./../../../src/pages/classes/tap.js" /* webpackChunkName: "component---src-pages-classes-tap-js" */),
  "component---src-pages-classes-technique-js": () => import("./../../../src/pages/classes/technique.js" /* webpackChunkName: "component---src-pages-classes-technique-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-costumes-js": () => import("./../../../src/pages/costumes.js" /* webpackChunkName: "component---src-pages-costumes-js" */),
  "component---src-pages-facility-js": () => import("./../../../src/pages/facility.js" /* webpackChunkName: "component---src-pages-facility-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-free-js": () => import("./../../../src/pages/free.js" /* webpackChunkName: "component---src-pages-free-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-check-js": () => import("./../../../src/pages/internal-check.js" /* webpackChunkName: "component---src-pages-internal-check-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-manage-pickup-js": () => import("./../../../src/pages/manage-pickup.js" /* webpackChunkName: "component---src-pages-manage-pickup-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privates-js": () => import("./../../../src/pages/privates.js" /* webpackChunkName: "component---src-pages-privates-js" */),
  "component---src-pages-prueba-js": () => import("./../../../src/pages/prueba.js" /* webpackChunkName: "component---src-pages-prueba-js" */),
  "component---src-pages-qrcode-js": () => import("./../../../src/pages/qrcode.js" /* webpackChunkName: "component---src-pages-qrcode-js" */),
  "component---src-pages-recital-js": () => import("./../../../src/pages/recital.js" /* webpackChunkName: "component---src-pages-recital-js" */),
  "component---src-pages-recital-live-js": () => import("./../../../src/pages/recital-live.js" /* webpackChunkName: "component---src-pages-recital-live-js" */),
  "component---src-pages-recitalprogram-js": () => import("./../../../src/pages/recitalprogram.js" /* webpackChunkName: "component---src-pages-recitalprogram-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-rehearsal-js": () => import("./../../../src/pages/rehearsal.js" /* webpackChunkName: "component---src-pages-rehearsal-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-sls-js": () => import("./../../../src/pages/sls.js" /* webpackChunkName: "component---src-pages-sls-js" */),
  "component---src-pages-specialnumbers-js": () => import("./../../../src/pages/specialnumbers.js" /* webpackChunkName: "component---src-pages-specialnumbers-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-pages-summer-camp-js": () => import("./../../../src/pages/summer-camp.js" /* webpackChunkName: "component---src-pages-summer-camp-js" */),
  "component---src-pages-summer-info-js": () => import("./../../../src/pages/summer-info.js" /* webpackChunkName: "component---src-pages-summer-info-js" */),
  "component---src-pages-summer-js": () => import("./../../../src/pages/summer.js" /* webpackChunkName: "component---src-pages-summer-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-trial-js": () => import("./../../../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-uniforms-js": () => import("./../../../src/pages/uniforms.js" /* webpackChunkName: "component---src-pages-uniforms-js" */),
  "component---src-pages-videos-2021-js": () => import("./../../../src/pages/videos/2021.js" /* webpackChunkName: "component---src-pages-videos-2021-js" */),
  "component---src-pages-videos-2022-js": () => import("./../../../src/pages/videos/2022.js" /* webpackChunkName: "component---src-pages-videos-2022-js" */),
  "component---src-pages-videos-2023-js": () => import("./../../../src/pages/videos/2023.js" /* webpackChunkName: "component---src-pages-videos-2023-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-pages-virtual-js": () => import("./../../../src/pages/virtual.js" /* webpackChunkName: "component---src-pages-virtual-js" */)
}

